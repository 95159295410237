@import url("https://fonts.googleapis.com/css?family=Dosis:400,700");

:root {
  --main-white-color: #f2f2f2;
  --main-black-color: black;
  --main-purple-color: #9e89b8;
}

.logo {
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font: 16px/1.5 "Dosis", sans-serif;
  /*IE FIX*/
  /*display: flex;
  flex-direction: column;*/
}

/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}


/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  background: var(--main-white-color);
  /* background: black; */
}
.page-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.page-header ul {
  display: flex;
  order: 1;
  width: 100%;
  margin-top: 15px;
}

.page-header ul li:not(:last-child) {
  padding-right: 15px;
}

.page-header .cta-contact {
  font-family: inherit;
  font-size: 1.2rem;
  padding: 5px 18px;
  border: 1px solid;
  border-radius: 5px;
}


/* MAIN
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* .page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-height: 350px;
  background: var(--main-purple-color)
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/city.jpg) no-repeat
    center / cover;
  background-blend-mode: luminosity;
  color: var(--main-white-color);
} */

.page-main div {
  max-width: 500px;
}

.page-main h1 {
  margin-bottom: 20px;
}

.page-main p + p {
  margin-top: 10px;
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  display: flex;
  flex-direction: column-reverse;
  background: var(--main-white-color);
}

.page-footer ul {
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.page-footer ul li:not(:last-child) {
  margin-right: 20px;
}


/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 550px) {
  .page-header ul {
    width: auto;
    margin-top: 0;
  }

  .page-header .cta-contact {
    order: 1;
  }

  .page-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer ul {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }

  .page-main {
    padding-left: 90px;
  }
}

/* LOWER SECTION LOWER SECTION */

.aboutPage-container {
  display: flex;
  flex-direction: row;
}

.lower_sec{
  margin-top: 50px;
  margin-left: 12%;
  margin-right: 12%;
}

.homepage-img-container {
  border-radius: 50%;
  margin-left: 4%;
  flex: 1;
}

.homepage-img-container img {
  border-radius: 50%;
}

.about-par {
  flex: 3;
}

@media screen and (max-width: 905px) {
  .aboutPage-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .homepage-img-container img {
    margin-top: 8%;
    flex: 1;
    align-items: center;
    justify-content: center;
    /* align-self: flex-start; */
  }
}

/* @media screen and (max-width: 875px) {
  .lower_sec {
    margin-left: 5%;
    margin-right: 5%;
  }
} */

@media screen and (max-width: 700px) {
  .lower_sec{
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (max-width: 850px) {
  .lower_sec{
    margin-left: 8%;
    margin-right: 8%;
  }
}


/* LOWER SECTION UPCMING EVENTS */

.upcoming-events-section {
  /* display: flex; */
  text-align: center;
  margin-bottom: 2%;
}

.upcoming-events-section img {
  margin-bottom: 1%;
  margin-top: -1%;
}

/* event card */

.event-container {
  display: flex;
  flex-direction: row;
  margin-left: 15%;
  margin-right: 15%;
  justify-content: center;
}

.event-img {
  height: 100%;
  width: 100%;
}

.event-card {
  padding-bottom: 20px;
  background-color: lightgrey;
  flex: 1;
  justify-content: space-between;
  /* max-width: 33%; */
  margin: 10px;
  /* flex-direction: column; */
}

.up-events {
  margin-bottom: 3%;
  margin-top: -1%;
}

@media screen and (max-width: 950px) {
.event-container {
  flex-direction: column;
  margin-left: 8%;
  margin-right: 8%;
  }

  .event-card {
    max-width: 100%;
    /* max-height: 20%; */
  }
}

@media screen and (min-width: 1200px) {
  
    .event-card {
        max-width: 33%;
    }
  }


  @media screen and (max-width: 422px) {
  
    .event-container {
      margin-left: 0%;
      margin-right: 0%;
    }
  }


