@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --main-blue-color: #1e375b;
  --main-white-color: #f2f2f2;
  --main-black-color: black;
  --main-purple-color: #3f6fc7;
  --main-color-2: white;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
  /* color: inherit; */
}

body {
  font: 16px/1.5 "Dosis", sans-serif;
  /*IE FIX*/
  /*display: flex;
  flex-direction: column;*/
}

/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}

/* .signupForm {
  color: yellow
} */


/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  background: var(--main-blue-color);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 100;

  /* background: rgba(0, 0, 0, 0.932); */
}
.page-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.page-header ul {
  display: flex;
  order: 1;
  width: 100%;
  margin-top: 15px;
}

.page-header ul li:not(:last-child) {
  padding-right: 15px;
}

.page-header .cta-contact {
  font-family: inherit;
  font-size: 1.2rem;
  padding: 5px 18px;
  border: 1px solid;
  border-radius: 5px;
}


/* MAIN
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.lower_sec {
  display: flex;
  flex-direction: row;
  /* justify-content: 'center'; */
}

.page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-height: 350px;
  background: 
    url('../home/homeImg/wp_field3.jpg') no-repeat
    center bottom / cover;
  background-blend-mode: luminosity;
  color: var(--main-white-color);
  border-bottom: lightslategrey 2px solid;
}

.box1 {
max-width:400px;
padding: 100px 600px;
border: lightslategrey solid 1px;
/* margin: 100px 200px; */
}

.content1 {
  margin: 20px;
}

.page-main div {
  max-width: 500px;
}

.page-main h1 {
  margin-bottom: 20px;
  text-shadow: 3px 3px black;
  text-align: center;
}

.page-main p + p {
  margin-top: 10px;
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  display: flex;
  flex-direction: column-reverse;
  /* background: var(--main-white-color); */
  background:#1e375b;
  color: white;
}

.page-footer ul {
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.page-footer ul li:not(:last-child) {
  margin-right: 20px;
}


/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 650px) {
.page-header li {
      display: none;
  }

  .cta-contact {
    display: none;
  }

  .hamburger-menu {
    text-align: right;
    margin-left: 30%;
  }
}

@media screen and (min-width: 651px) {
  .hamburger-menu {
    display: none;
    }
  }



@media screen and (min-width: 550px) {
  .page-header ul {
    width: auto;
    margin-top: 0;
  }

  .page-header .cta-contact {
    order: 1;
  }

  .page-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer ul {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }

  .page-main {
    padding-left: 90px;
  }
}

/* Below is all for mobile devices */

@media screen and (max-width: 415px) {
  
  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 23px;
  }
}

@media screen and (max-width: 376px) {

  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 22px;
  }
}

@media screen and (max-width: 330px) {

  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 19px;
  }
}


/* Hambuger Menu Dropdown */

/* The ul styling */

.navbar{
  background-color: black;
}
.hamburgerDropDown {
  position: fixed;
  left: 40px;
  top: 40px;
  width: 300px;
  background-color: black;
  border: 1px solid bisque;
}

ul.hamburgerDropDown {
  padding: 10px;
}

/* Position of the hamburger symbol */
.navbar div {
  right: -140px;
  position: relative;
  display: inline-block;
}



.MuiButton-root {
  color: white !important;
}

.MuiMenu-list {
background-color: white !important;
color: white !important;
}

#test-ref {
  color: black !important;
}