.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}

.sponsors-banner {
  background-color: rgba(148, 148, 148, 0.486);
  max-height: 50px;
}

.sponsors-banner-msg {
  vertical-align: middle;
  text-align: center;
}