.donationContainer {
    display: flex;
    flex-direction: column;
    background-color: rgb(135, 190, 253);
    border: rgb(170, 170, 170) solid 4px;
    margin-left: 4%;
    margin-right: 4%;
}

.donationPerson {
    border-bottom: 2px black solid;
    background-color: white;
    margin: 5px;
    border: 1px solid slategray;
    padding: 4px;
}
  
  .formDonation {
    border: 1px solid #555555;
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 5%;
  }
  
  .titleDonation {
    font-size: 27px;
    padding-right: 15px;
  }
  
  .btn {
    position: relative;
    vertical-align: top;
    height: 40px;
    width: 25%;
    padding: 0;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #4d4d4d;
    border: 0;
    border-bottom: 2px solid #d0d0d0;
    cursor: pointer;
    margin-left: auto;
    margin-top: 1%;
  }
  
  .formDonation input:not([type="submit"]){
    flex: 1;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  
  .formDonation p {
    display: flex;
  }
  
  .formDonation label {
      width: 7em;
      margin-right: .5em;
  }
  
  @media (max-width: 600px) {
    .formDonation p {
      display: block;
    }
    .formDonation input {
      width: 100%;
    }
    
    .formDonation input[type="submit"] {
      width: 100%;
    }
  }

  /*****************************
  ******************************/

  .body-donation {
    font-family: Arial;
    font-size: 17px;
    padding: 8px;
  }

  /* * {
    box-sizing: border-box;
  } */
  
  .donate-row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  
  .col-25 {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
  }
  
  .col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
  }
  
  .col-75 {
    -ms-flex: 75%; /* IE10 */
    flex: 75%;
  }
  
  .col-25,
  .col-50,
  .col-75 {
    padding: 0 16px;
  }
  
  .container-d {
    background-color: #f2f2f2;
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .input-donation-text {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .label-donations {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
  }
  
  .btn-donations {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    margin: 10px 0;
    border: none;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
  }
  
  .btn:hover {
    background-color: #1e375b;
  }
  
  /* a {
    color: #2196F3;
  }
  
  hr {
    border: 1px solid lightgrey;
  } */
  
  /* span.price {
    float: right;
    color: grey;
  } */
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
  @media (max-width: 800px) {
    .container-d {
      max-width: 100%;
    }
    .donate-row {
      flex-direction: column;
    }
    .col-25 {
      margin-bottom: 20px;
    }
  }