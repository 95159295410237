.about-body {
    border: solid lightgrey 2.5px;
    margin-top: 3%;
    margin-left: 18%;
    margin-right: 18%;
    overflow-y: auto;
    height: 500px;
  }
  
  .about-card {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    min-height: 300px;
  }

  .about-header h2 {
      text-align: center;
      margin-bottom: 2%;
  }
  
  .about-img {
    border-radius: 50%;
    margin-right: 3%;
    float: left;
  }
  
  .about-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .about-details-container {
    flex: 2;
  }
  
  /*delete one before this!!!! */
  
  .avatar-container {
    flex-basis: 100%;
    /* flex-basis: 250px; */
  }


  @media screen and (max-width: 1500px) {
    .about-body {
        margin-left: 11%;
        margin-right: 11%;
      }
    }

  @media screen and (max-width: 730px) {
    .about-body {
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  
    @media screen and (max-width: 605px) {
        .about-body {
            margin-left: 7%;
            margin-right: 7%;
          }
        }

        @media screen and (max-width: 501px) {
            .about-body {
                margin-left: 8%;
                margin-right: 8%;
              }
            }
    

  /* iphone width is 375px */

  @media screen and (max-width: 415px) {
    .about-body {
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .about-img {
        margin-left: 12%;
        margin-top: 2%;
        margin-bottom: 4%;
      }
  }

  @media screen and (max-width: 376px) {
    .about-body {
      height: 400px;
    }

    .about-img {
      margin-left: 3%;
    }
  }
  
  @media screen and (max-width: 330px) {
    .about-img {
      margin-left: 0%;
      margin-right: 5%;
    }
  }
  
  /* MEET THE BOARD SECTION */

  .board-container {
    margin-top: .5%;
    text-align: center;
  }

  .main-area {
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* max-width: 80%; */
    align-content: flex-start;
  }

  .centered {
    flex: 1;
    max-width: 50%;
    /* margin: 1px; */
  }

  .card-content{
    /* padding:5px; */
    background-color: lightgrey;
  }


  /* MEEEEEEET */

  /* html {
  background: #f5f7f8;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 20px 0;
} */

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 2%;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;  
}

.band2 {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 2%;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;  
}

.item-1 {
  /* display: flex;
  justify-content: center; */
}

@media only screen and (min-width: 500px) {
  .band {
    grid-template-columns: 1fr 1fr;
  }  

  .band2 {
    grid-template-columns: 1fr 1fr;
  }  
  .item-1 {
  grid-column: 1/ span 2;
  }
  .item-1 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 850px) {

  .item-1 {
    display: 0;
    justify-content: 0;
  }

  .band {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .band2 {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  }
}

/* card */

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  /* box-shadow: 5px 5px 5px 5px rgba(0,0,0); */
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #444;
  position: relative;
  top: 0;
  transition: all .1s ease-in;
}

.card:hover {
  top: -2px;
  box-shadow: 0 4px 5px rgba(0,0,0,0.2);
}

.card article {
  padding: 20px;
  display: flex;
  
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
}

.card p { 
  flex: 1; /* make p grow to fill available space*/
  line-height: 1.4;
}

.card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 2em 0 0 0;
}

 
/* Mission Statement */

.mission-statement {
  display: flex;
  margin-left: 23%;
  margin-right: 23%;
  margin-top: 2%;
  margin-bottom: 5%;
}

@media only screen and (max-width: 12010x) {
  .mission-statement{
    margin-left: 18%;
    margin-right: 18%;
  }
}

@media only screen and (max-width: 12010x) {
  .mission-statement{
    margin-left: 18%;
    margin-right: 18%;
  }
}

@media only screen and (max-width: 1000px) {
  .mission-statement{
    margin-left: 12%;
    margin-right: 12%;
  }
}

@media only screen and (max-width: 810px) {
  .mission-statement{
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media only screen and (max-width: 650px) {
  .mission-statement{
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 530px) {
  .mission-statement{
    margin-left: 2%;
    margin-right: 2%;
  }
}



/* New from sponsors */

.sponsors-container {
  text-align: center;
}

.about-logo-img {
  /* width: 100% */
  /* width: 5%; */
}

.cont-img {
  margin: 25px 50px;
  vertical-align: middle;
  width: 450px;
}

@media only screen and (max-width: 850px) {
  .cont-img {
    margin: 25px 50px;
    vertical-align: middle;
    width: 320px;
  }
}

@media only screen and (max-width: 400px) {
  .cont-img {
    margin: 25px 50px;
    vertical-align: middle;
    width: 290px;
  }
}

@media only screen and (max-width: 300px) {
  .cont-img {
    margin: 0px 0px;
    vertical-align: middle;
    width: 4px;
  }
}







/* New from sponsors */





/* NEW NEW NEW NEW */
.sponsors-page-container {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
}

.sponsors-f-1 {
  flex: 1;
}

.sponsorsImg1 {
max-width: 400px;
padding: 20px;
vertical-align: middle;
}

#img-alt-1 {
  max-width: 300px;
}

@media only screen and (max-width: 800px) {
  .sponsors-page-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  .sponsorsImg1 {
    max-width: 300px;

    }

    #img-alt-1 {
      max-width: 300px;
    }
}

@media only screen and (max-width: 310px) {
  .sponsorsImg1 {
    max-width: 200px;

    }

    #img-alt-1 {
      max-width: 200px;
    }
}

.sponsors-f-1 h2 {
padding: 10px;
}