.signUp-section {
background-color: rgb(21, 14, 82);
/* height: 100vh;  */
font-size: 15px;
}

.signUp-container {
    background-color: #f8f8f8;
    min-height: 90vh;
}
.signUp-section2 {
    background-color: #f8f8f8;
    /* height: 20vh;  */
    font-size: 15px;
    }

.signUp-box {
    text-align: center;
} 

.signUp-form {
    border-radius: 4px;
    display: inline-block;
    background-color: white;
    max-width: 40%;
    width: 80%;
    min-width: 320px;
    padding: 1%;
}

.signUp-inner {
    padding-top: 4%;
    padding-bottom: 3%;
}

.input-fields li{
    list-style: none;
    padding-bottom: 2%;
    }
    
    .input-label{
        display: block;
        color: darkslategrey;
        text-align: left;
        margin-left: 3%;
    }
    .input-input{
        display: block;
        margin-left: 3%;
        width: 90%;
        min-height: 34px;
        border: solid 1px rgba(0, 0, 0, .3);
        font-size: 13px;
    }

    .input-fields li select {
        display: block;
        width: 200px;
        margin-left: 3%;
        font-size: 17px;
        border: solid 1px rgba(0, 0, 0, .9);
        /* max-height: 300px; */
    }

    .k-radio-label {
    margin-left: 1%;    
    font-size: 20px;
}

.sign-up-form-btn {
    border-radius: 20px;
}

.sign-up-form-btn {
    border-radius: 20px;
}

.event-types-form {
    margin-top: 2%;
}

.spin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    }
    @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }

    /* MATERIAL UIIII */
    
    .form-type-1 {
        /* color: pink; */
        padding: 3px;
    }

    #m-ui-signup-btn {
        color: white;
        background-color: rgb(146, 146, 146);
        padding: 10px 15px;
        margin: 10px;
    }

    #m-ui-signup-btn:hover {
        background-color: #1e375b;
    }

    .MuiMenuItem-root {
        color: black !important;
    }