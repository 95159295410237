     
     .events-body-card {
        margin: 10%;
        margin-top: -5%;
        border-style: ridge;
      }

      .e-card {
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        min-height: 300px;
      }
      
      .e-img {
        border-radius: 50%;
        height: 200px;
        width: 200px;
      }
      
      .e-row {
        display: flex;
        flex-wrap: wrap;
      }
      
      .e-details-container {
        flex: 1;
      }
      
      .e-avatar-container {
        flex-basis: 250px;
      }

      .e-details-container {
        flex: 2;
        padding: 12px;
      }
      
      .e-avatar-container {
        flex-basis: 450px;
      }

      .e-content {
          display: flex;
      }

      .e-content1 {
        flex: 1
    }


      /* Main Page */

      .events-page-container {
          margin: 12%;
          margin-top: 2%;
          margin-bottom: 1%;
      }

    @media screen and (max-width: 801px) {
      .events-page-container {
        margin: 7%;
        margin-top: 2%;
        margin-bottom: 1%;
      }
    }

      .e-object2 {
        display: flex;
      }
      
      .band3 {
        margin-left: 30%;

        width: 90%;
        max-width: 1240px;
        margin: 0 auto;
        margin-top: 2%;    
        flex: 1;    
      }
      
      
      /* card */
      
      .card3 {
        min-height: 100%;
        background: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        /* box-shadow: 5px 5px 5px 5px rgba(0,0,0); */
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: #444;
        position: relative;
        top: 0;
        transition: all .1s ease-in;
      }
      
      .card3:hover {
        top: -2px;
        box-shadow: 0 4px 5px rgba(0,0,0,0.2);
      }
      
      .card3 article {
        padding: 20px;
        display: flex;
        
        flex: 1;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
      }
      .card3 .thumb3 {
        padding-bottom: 40%;
        background-size: cover;
        background-position: center center;
      }
      
      .card3 p { 
        flex: 1; /* make p grow to fill available space*/
        line-height: 1.4;
      }
      
      .card3 span {
        font-size: 12px;
        font-weight: bold;
        color: #999;
        text-transform: uppercase;
        letter-spacing: .05em;
        margin: 2em 0 0 0;
      }

      .ops {
          display: flex;
          flex-direction: row;
        }

        .ops2 {
            flex: 2;
            text-align: center;
            justify-content: center;
        }

        .ops2 li {
            list-style: none;
            margin: 4px;
        }

        .ops2 h3{
           font-weight: bold;
           color: #1e375b;
        }

        .upcoming-events-section2 {
            /* display: flex; */
            text-align: center;
            margin-bottom: -2%;
          }
      

.signUp-btn {
  background-color: #1e375b;
  border: none;
  border-radius: 2rem;
  width: 6rem;
  display: inline-block;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .4);
}

.closed-reg-sec {
  padding: 1.5%;
  line-height: 1.7;
}

.brochure {
  color: #1e375b
}