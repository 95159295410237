/* input[type=text], select {
    width: 30%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 50%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }

  .FormBox {
      background-color: white;
      padding: 30px;
      border: solid lightgrey;
  } */

  .header-fund{
      font-size: 26px;
      text-align: center;
      margin-bottom: 12px;
      margin-top: 10px;
  }

  .form-Outter { 
    font: normal 18px/1.5 "Fira Sans", "Helvetica Neue", sans-serif;
    background: white;
    color: black;
    padding: 50px;
    /* max-width: 50%; */
    /* margin-left: 25%; */
    border: lightgrey solid;
  }

  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .container * {
    box-sizing: border-box;
  }
  
  .flex-outer,
  .flex-inner {
    list-style-type: none;
    padding: 0;
  }
  
  .flex-outer {
    /* max-width: 800px; */
    margin: 0 auto;
  }
  
  .flex-outer li,
  .flex-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .flex-inner {
    padding: 0 8px;
    justify-content: space-between;  
  }
  
  .flex-outer > li:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .flex-outer li label,
  .flex-outer li p {
    padding: 8px;
    font-weight: 300;
    letter-spacing: .09em;
    text-transform: uppercase;
  }
  
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 220px;
  }
  
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 220px;
  }
  
  .flex-outer li p {
    margin: 0;
  }
  
  .flex-outer li input:not([type='checkbox']),
  .flex-outer li textarea {
    padding: 15px;
    /* border: none; */
  }
  
  .flex-outer li button {
    margin-left: auto;
    padding: 14px 22px;
    border: none;
    background: #333;
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: .09em;
    border-radius: 2px;
    font-size: 18px;
  }
  
  .flex-inner li {
    width: 100px;
  }

  .thank-you {
    color: red;
    text-align: center;
    font-style: oblique;
    font-size: 18px;
    /* padding-left: 10%; */
  }
  
  /* div {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  } */