@import url(https://fonts.googleapis.com/css?family=Dosis:400,700);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.about-body {
    border: solid lightgrey 2.5px;
    margin-top: 3%;
    margin-left: 18%;
    margin-right: 18%;
    overflow-y: auto;
    height: 500px;
  }
  
  .about-card {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    min-height: 300px;
  }

  .about-header h2 {
      text-align: center;
      margin-bottom: 2%;
  }
  
  .about-img {
    border-radius: 50%;
    margin-right: 3%;
    float: left;
  }
  
  .about-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .about-details-container {
    flex: 2 1;
  }
  
  /*delete one before this!!!! */
  
  .avatar-container {
    flex-basis: 100%;
    /* flex-basis: 250px; */
  }


  @media screen and (max-width: 1500px) {
    .about-body {
        margin-left: 11%;
        margin-right: 11%;
      }
    }

  @media screen and (max-width: 730px) {
    .about-body {
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  
    @media screen and (max-width: 605px) {
        .about-body {
            margin-left: 7%;
            margin-right: 7%;
          }
        }

        @media screen and (max-width: 501px) {
            .about-body {
                margin-left: 8%;
                margin-right: 8%;
              }
            }
    

  /* iphone width is 375px */

  @media screen and (max-width: 415px) {
    .about-body {
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .about-img {
        margin-left: 12%;
        margin-top: 2%;
        margin-bottom: 4%;
      }
  }

  @media screen and (max-width: 376px) {
    .about-body {
      height: 400px;
    }

    .about-img {
      margin-left: 3%;
    }
  }
  
  @media screen and (max-width: 330px) {
    .about-img {
      margin-left: 0%;
      margin-right: 5%;
    }
  }
  
  /* MEET THE BOARD SECTION */

  .board-container {
    margin-top: .5%;
    text-align: center;
  }

  .main-area {
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* max-width: 80%; */
    align-content: flex-start;
  }

  .centered {
    flex: 1 1;
    max-width: 50%;
    /* margin: 1px; */
  }

  .card-content{
    /* padding:5px; */
    background-color: lightgrey;
  }


  /* MEEEEEEET */

  /* html {
  background: #f5f7f8;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 20px 0;
} */

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 2%;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;  
}

.band2 {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 2%;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;  
}

.item-1 {
  /* display: flex;
  justify-content: center; */
}

@media only screen and (min-width: 500px) {
  .band {
    grid-template-columns: 1fr 1fr;
  }  

  .band2 {
    grid-template-columns: 1fr 1fr;
  }  
  .item-1 {
  grid-column: 1/ span 2;
  }
  .item-1 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 850px) {

  .item-1 {
    display: 0;
    justify-content: 0;
  }

  .band {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .band2 {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  }
}

/* card */

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  /* box-shadow: 5px 5px 5px 5px rgba(0,0,0); */
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #444;
  position: relative;
  top: 0;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

.card:hover {
  top: -2px;
  box-shadow: 0 4px 5px rgba(0,0,0,0.2);
}

.card article {
  padding: 20px;
  display: flex;
  
  flex: 1 1;
  justify-content: space-between;
  flex-direction: column;
  
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
}

.card p { 
  flex: 1 1; /* make p grow to fill available space*/
  line-height: 1.4;
}

.card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 2em 0 0 0;
}

 
/* Mission Statement */

.mission-statement {
  display: flex;
  margin-left: 23%;
  margin-right: 23%;
  margin-top: 2%;
  margin-bottom: 5%;
}

@media only screen and (max-width: 12010x) {
  .mission-statement{
    margin-left: 18%;
    margin-right: 18%;
  }
}

@media only screen and (max-width: 12010x) {
  .mission-statement{
    margin-left: 18%;
    margin-right: 18%;
  }
}

@media only screen and (max-width: 1000px) {
  .mission-statement{
    margin-left: 12%;
    margin-right: 12%;
  }
}

@media only screen and (max-width: 810px) {
  .mission-statement{
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media only screen and (max-width: 650px) {
  .mission-statement{
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 530px) {
  .mission-statement{
    margin-left: 2%;
    margin-right: 2%;
  }
}



/* New from sponsors */

.sponsors-container {
  text-align: center;
}

.about-logo-img {
  /* width: 100% */
  /* width: 5%; */
}

.cont-img {
  margin: 25px 50px;
  vertical-align: middle;
  width: 450px;
}

@media only screen and (max-width: 850px) {
  .cont-img {
    margin: 25px 50px;
    vertical-align: middle;
    width: 320px;
  }
}

@media only screen and (max-width: 400px) {
  .cont-img {
    margin: 25px 50px;
    vertical-align: middle;
    width: 290px;
  }
}

@media only screen and (max-width: 300px) {
  .cont-img {
    margin: 0px 0px;
    vertical-align: middle;
    width: 4px;
  }
}







/* New from sponsors */





/* NEW NEW NEW NEW */
.sponsors-page-container {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
}

.sponsors-f-1 {
  flex: 1 1;
}

.sponsorsImg1 {
max-width: 400px;
padding: 20px;
vertical-align: middle;
}

#img-alt-1 {
  max-width: 300px;
}

@media only screen and (max-width: 800px) {
  .sponsors-page-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  .sponsorsImg1 {
    max-width: 300px;

    }

    #img-alt-1 {
      max-width: 300px;
    }
}

@media only screen and (max-width: 310px) {
  .sponsorsImg1 {
    max-width: 200px;

    }

    #img-alt-1 {
      max-width: 200px;
    }
}

.sponsors-f-1 h2 {
padding: 10px;
}
:root {
  --main-white-color: #f2f2f2;
  --main-black-color: black;
  --main-purple-color: #9e89b8;
}

.logo {
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font: 16px/1.5 "Dosis", sans-serif;
  /*IE FIX*/
  /*display: flex;
  flex-direction: column;*/
}

/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}


/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  background: #f2f2f2;
  background: var(--main-white-color);
  /* background: black; */
}
.page-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.page-header ul {
  display: flex;
  order: 1;
  width: 100%;
  margin-top: 15px;
}

.page-header ul li:not(:last-child) {
  padding-right: 15px;
}

.page-header .cta-contact {
  font-family: inherit;
  font-size: 1.2rem;
  padding: 5px 18px;
  border: 1px solid;
  border-radius: 5px;
}


/* MAIN
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* .page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-height: 350px;
  background: var(--main-purple-color)
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/city.jpg) no-repeat
    center / cover;
  background-blend-mode: luminosity;
  color: var(--main-white-color);
} */

.page-main div {
  max-width: 500px;
}

.page-main h1 {
  margin-bottom: 20px;
}

.page-main p + p {
  margin-top: 10px;
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  display: flex;
  flex-direction: column-reverse;
  background: #f2f2f2;
  background: var(--main-white-color);
}

.page-footer ul {
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.page-footer ul li:not(:last-child) {
  margin-right: 20px;
}


/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 550px) {
  .page-header ul {
    width: auto;
    margin-top: 0;
  }

  .page-header .cta-contact {
    order: 1;
  }

  .page-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer ul {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }

  .page-main {
    padding-left: 90px;
  }
}

/* LOWER SECTION LOWER SECTION */

.aboutPage-container {
  display: flex;
  flex-direction: row;
}

.lower_sec{
  margin-top: 50px;
  margin-left: 12%;
  margin-right: 12%;
}

.homepage-img-container {
  border-radius: 50%;
  margin-left: 4%;
  flex: 1 1;
}

.homepage-img-container img {
  border-radius: 50%;
}

.about-par {
  flex: 3 1;
}

@media screen and (max-width: 905px) {
  .aboutPage-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .homepage-img-container img {
    margin-top: 8%;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    /* align-self: flex-start; */
  }
}

/* @media screen and (max-width: 875px) {
  .lower_sec {
    margin-left: 5%;
    margin-right: 5%;
  }
} */

@media screen and (max-width: 700px) {
  .lower_sec{
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media screen and (max-width: 850px) {
  .lower_sec{
    margin-left: 8%;
    margin-right: 8%;
  }
}


/* LOWER SECTION UPCMING EVENTS */

.upcoming-events-section {
  /* display: flex; */
  text-align: center;
  margin-bottom: 2%;
}

.upcoming-events-section img {
  margin-bottom: 1%;
  margin-top: -1%;
}

/* event card */

.event-container {
  display: flex;
  flex-direction: row;
  margin-left: 15%;
  margin-right: 15%;
  justify-content: center;
}

.event-img {
  height: 100%;
  width: 100%;
}

.event-card {
  padding-bottom: 20px;
  background-color: lightgrey;
  flex: 1 1;
  justify-content: space-between;
  /* max-width: 33%; */
  margin: 10px;
  /* flex-direction: column; */
}

.up-events {
  margin-bottom: 3%;
  margin-top: -1%;
}

@media screen and (max-width: 950px) {
.event-container {
  flex-direction: column;
  margin-left: 8%;
  margin-right: 8%;
  }

  .event-card {
    max-width: 100%;
    /* max-height: 20%; */
  }
}

@media screen and (min-width: 1200px) {
  
    .event-card {
        max-width: 33%;
    }
  }


  @media screen and (max-width: 422px) {
  
    .event-container {
      margin-left: 0%;
      margin-right: 0%;
    }
  }



/* input[type=text], select {
    width: 30%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 50%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }

  .FormBox {
      background-color: white;
      padding: 30px;
      border: solid lightgrey;
  } */

  .header-fund{
      font-size: 26px;
      text-align: center;
      margin-bottom: 12px;
      margin-top: 10px;
  }

  .form-Outter { 
    font: normal 18px/1.5 "Fira Sans", "Helvetica Neue", sans-serif;
    background: white;
    color: black;
    padding: 50px;
    /* max-width: 50%; */
    /* margin-left: 25%; */
    border: lightgrey solid;
  }

  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .container * {
    box-sizing: border-box;
  }
  
  .flex-outer,
  .flex-inner {
    list-style-type: none;
    padding: 0;
  }
  
  .flex-outer {
    /* max-width: 800px; */
    margin: 0 auto;
  }
  
  .flex-outer li,
  .flex-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .flex-inner {
    padding: 0 8px;
    justify-content: space-between;  
  }
  
  .flex-outer > li:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .flex-outer li label,
  .flex-outer li p {
    padding: 8px;
    font-weight: 300;
    letter-spacing: .09em;
    text-transform: uppercase;
  }
  
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 220px;
  }
  
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 220px;
  }
  
  .flex-outer li p {
    margin: 0;
  }
  
  .flex-outer li input:not([type='checkbox']),
  .flex-outer li textarea {
    padding: 15px;
    /* border: none; */
  }
  
  .flex-outer li button {
    margin-left: auto;
    padding: 14px 22px;
    border: none;
    background: #333;
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: .09em;
    border-radius: 2px;
    font-size: 18px;
  }
  
  .flex-inner li {
    width: 100px;
  }

  .thank-you {
    color: red;
    text-align: center;
    font-style: oblique;
    font-size: 18px;
    /* padding-left: 10%; */
  }
  
  /* div {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  } */
.donationContainer {
    display: flex;
    flex-direction: column;
    background-color: rgb(135, 190, 253);
    border: rgb(170, 170, 170) solid 4px;
    margin-left: 4%;
    margin-right: 4%;
}

.donationPerson {
    border-bottom: 2px black solid;
    background-color: white;
    margin: 5px;
    border: 1px solid slategray;
    padding: 4px;
}
  
  .formDonation {
    border: 1px solid #555555;
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 5%;
  }
  
  .titleDonation {
    font-size: 27px;
    padding-right: 15px;
  }
  
  .btn {
    position: relative;
    vertical-align: top;
    height: 40px;
    width: 25%;
    padding: 0;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #4d4d4d;
    border: 0;
    border-bottom: 2px solid #d0d0d0;
    cursor: pointer;
    margin-left: auto;
    margin-top: 1%;
  }
  
  .formDonation input:not([type="submit"]){
    flex: 1 1;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  
  .formDonation p {
    display: flex;
  }
  
  .formDonation label {
      width: 7em;
      margin-right: .5em;
  }
  
  @media (max-width: 600px) {
    .formDonation p {
      display: block;
    }
    .formDonation input {
      width: 100%;
    }
    
    .formDonation input[type="submit"] {
      width: 100%;
    }
  }

  /*****************************
  ******************************/

  .body-donation {
    font-family: Arial;
    font-size: 17px;
    padding: 8px;
  }

  /* * {
    box-sizing: border-box;
  } */
  
  .donate-row { /* IE10 */
    display: flex; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  
  .col-25 { /* IE10 */
    flex: 25% 1;
  }
  
  .col-50 { /* IE10 */
    flex: 50% 1;
  }
  
  .col-75 { /* IE10 */
    flex: 75% 1;
  }
  
  .col-25,
  .col-50,
  .col-75 {
    padding: 0 16px;
  }
  
  .container-d {
    background-color: #f2f2f2;
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .input-donation-text {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .label-donations {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
  }
  
  .btn-donations {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    margin: 10px 0;
    border: none;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
  }
  
  .btn:hover {
    background-color: #1e375b;
  }
  
  /* a {
    color: #2196F3;
  }
  
  hr {
    border: 1px solid lightgrey;
  } */
  
  /* span.price {
    float: right;
    color: grey;
  } */
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
  @media (max-width: 800px) {
    .container-d {
      max-width: 100%;
    }
    .donate-row {
      flex-direction: column;
    }
    .col-25 {
      margin-bottom: 20px;
    }
  }
     
     .events-body-card {
        margin: 10%;
        margin-top: -5%;
        border-style: ridge;
      }

      .e-card {
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        min-height: 300px;
      }
      
      .e-img {
        border-radius: 50%;
        height: 200px;
        width: 200px;
      }
      
      .e-row {
        display: flex;
        flex-wrap: wrap;
      }
      
      .e-details-container {
        flex: 1 1;
      }
      
      .e-avatar-container {
        flex-basis: 250px;
      }

      .e-details-container {
        flex: 2 1;
        padding: 12px;
      }
      
      .e-avatar-container {
        flex-basis: 450px;
      }

      .e-content {
          display: flex;
      }

      .e-content1 {
        flex: 1 1
    }


      /* Main Page */

      .events-page-container {
          margin: 12%;
          margin-top: 2%;
          margin-bottom: 1%;
      }

    @media screen and (max-width: 801px) {
      .events-page-container {
        margin: 7%;
        margin-top: 2%;
        margin-bottom: 1%;
      }
    }

      .e-object2 {
        display: flex;
      }
      
      .band3 {
        margin-left: 30%;

        width: 90%;
        max-width: 1240px;
        margin: 0 auto;
        margin-top: 2%;    
        flex: 1 1;    
      }
      
      
      /* card */
      
      .card3 {
        min-height: 100%;
        background: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        /* box-shadow: 5px 5px 5px 5px rgba(0,0,0); */
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: #444;
        position: relative;
        top: 0;
        -webkit-transition: all .1s ease-in;
        transition: all .1s ease-in;
      }
      
      .card3:hover {
        top: -2px;
        box-shadow: 0 4px 5px rgba(0,0,0,0.2);
      }
      
      .card3 article {
        padding: 20px;
        display: flex;
        
        flex: 1 1;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
      }
      .card3 .thumb3 {
        padding-bottom: 40%;
        background-size: cover;
        background-position: center center;
      }
      
      .card3 p { 
        flex: 1 1; /* make p grow to fill available space*/
        line-height: 1.4;
      }
      
      .card3 span {
        font-size: 12px;
        font-weight: bold;
        color: #999;
        text-transform: uppercase;
        letter-spacing: .05em;
        margin: 2em 0 0 0;
      }

      .ops {
          display: flex;
          flex-direction: row;
        }

        .ops2 {
            flex: 2 1;
            text-align: center;
            justify-content: center;
        }

        .ops2 li {
            list-style: none;
            margin: 4px;
        }

        .ops2 h3{
           font-weight: bold;
           color: #1e375b;
        }

        .upcoming-events-section2 {
            /* display: flex; */
            text-align: center;
            margin-bottom: -2%;
          }
      

.signUp-btn {
  background-color: #1e375b;
  border: none;
  border-radius: 2rem;
  width: 6rem;
  display: inline-block;
}

.btn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .4);
}

.closed-reg-sec {
  padding: 1.5%;
  line-height: 1.7;
}

.brochure {
  color: #1e375b
}
.signUp-section {
background-color: rgb(21, 14, 82);
/* height: 100vh;  */
font-size: 15px;
}

.signUp-container {
    background-color: #f8f8f8;
    min-height: 90vh;
}
.signUp-section2 {
    background-color: #f8f8f8;
    /* height: 20vh;  */
    font-size: 15px;
    }

.signUp-box {
    text-align: center;
} 

.signUp-form {
    border-radius: 4px;
    display: inline-block;
    background-color: white;
    max-width: 40%;
    width: 80%;
    min-width: 320px;
    padding: 1%;
}

.signUp-inner {
    padding-top: 4%;
    padding-bottom: 3%;
}

.input-fields li{
    list-style: none;
    padding-bottom: 2%;
    }
    
    .input-label{
        display: block;
        color: darkslategrey;
        text-align: left;
        margin-left: 3%;
    }
    .input-input{
        display: block;
        margin-left: 3%;
        width: 90%;
        min-height: 34px;
        border: solid 1px rgba(0, 0, 0, .3);
        font-size: 13px;
    }

    .input-fields li select {
        display: block;
        width: 200px;
        margin-left: 3%;
        font-size: 17px;
        border: solid 1px rgba(0, 0, 0, .9);
        /* max-height: 300px; */
    }

    .k-radio-label {
    margin-left: 1%;    
    font-size: 20px;
}

.sign-up-form-btn {
    border-radius: 20px;
}

.sign-up-form-btn {
    border-radius: 20px;
}

.event-types-form {
    margin-top: 2%;
}

.spin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    }
    @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }

    /* MATERIAL UIIII */
    
    .form-type-1 {
        /* color: pink; */
        padding: 3px;
    }

    #m-ui-signup-btn {
        color: white;
        background-color: rgb(146, 146, 146);
        padding: 10px 15px;
        margin: 10px;
    }

    #m-ui-signup-btn:hover {
        background-color: #1e375b;
    }

    .MuiMenuItem-root {
        color: black !important;
    }
.private-section-golf-list {
    background-color: #fff;
    border: black solid 1.3px;
    padding: 5px;
    margin-bottom: 5px;
}

.private-section-golf h3 {
    text-transform: uppercase ;
}

.private-button-list button {
    font-size: 15px;;
    padding: 12px;
    margin: 5px;
    margin-bottom: 15px;
}

/* FORM */

.private-error {
    color: red;
}

.private-signin-h1 {
    text-align: center;
    margin: 5px;
}
.private-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.private-button2 {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.sign-in-container {
  padding: 30px;
  max-width: 75%;
  border: black solid 2px;
  border-radius: 20px;
  margin: auto;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}
:root {
  --main-blue-color: #1e375b;
  --main-white-color: #f2f2f2;
  --main-black-color: black;
  --main-purple-color: #3f6fc7;
  --main-color-2: white;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
  /* color: inherit; */
}

body {
  font: 16px/1.5 "Dosis", sans-serif;
  /*IE FIX*/
  /*display: flex;
  flex-direction: column;*/
}

/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}

/* .signupForm {
  color: yellow
} */


/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  background: #1e375b;
  background: var(--main-blue-color);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 100;

  /* background: rgba(0, 0, 0, 0.932); */
}
.page-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.page-header ul {
  display: flex;
  order: 1;
  width: 100%;
  margin-top: 15px;
}

.page-header ul li:not(:last-child) {
  padding-right: 15px;
}

.page-header .cta-contact {
  font-family: inherit;
  font-size: 1.2rem;
  padding: 5px 18px;
  border: 1px solid;
  border-radius: 5px;
}


/* MAIN
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.lower_sec {
  display: flex;
  flex-direction: row;
  /* justify-content: 'center'; */
}

.page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-height: 350px;
  background: 
    url(/static/media/wp_field3.e524ff7b.jpg) no-repeat
    center bottom / cover;
  background-blend-mode: luminosity;
  color: #f2f2f2;
  color: var(--main-white-color);
  border-bottom: lightslategrey 2px solid;
}

.box1 {
max-width:400px;
padding: 100px 600px;
border: lightslategrey solid 1px;
/* margin: 100px 200px; */
}

.content1 {
  margin: 20px;
}

.page-main div {
  max-width: 500px;
}

.page-main h1 {
  margin-bottom: 20px;
  text-shadow: 3px 3px black;
  text-align: center;
}

.page-main p + p {
  margin-top: 10px;
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  display: flex;
  flex-direction: column-reverse;
  /* background: var(--main-white-color); */
  background:#1e375b;
  color: white;
}

.page-footer ul {
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.page-footer ul li:not(:last-child) {
  margin-right: 20px;
}


/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 650px) {
.page-header li {
      display: none;
  }

  .cta-contact {
    display: none;
  }

  .hamburger-menu {
    text-align: right;
    margin-left: 30%;
  }
}

@media screen and (min-width: 651px) {
  .hamburger-menu {
    display: none;
    }
  }



@media screen and (min-width: 550px) {
  .page-header ul {
    width: auto;
    margin-top: 0;
  }

  .page-header .cta-contact {
    order: 1;
  }

  .page-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer ul {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }

  .page-main {
    padding-left: 90px;
  }
}

/* Below is all for mobile devices */

@media screen and (max-width: 415px) {
  
  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 23px;
  }
}

@media screen and (max-width: 376px) {

  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 22px;
  }
}

@media screen and (max-width: 330px) {

  .hamburger-menu {
    text-align: right;
    float: right;
    margin-left: 0%;
  }

  .logo {
    font-size: 19px;
  }
}


/* Hambuger Menu Dropdown */

/* The ul styling */

.navbar{
  background-color: black;
}
.hamburgerDropDown {
  position: fixed;
  left: 40px;
  top: 40px;
  width: 300px;
  background-color: black;
  border: 1px solid bisque;
}

ul.hamburgerDropDown {
  padding: 10px;
}

/* Position of the hamburger symbol */
.navbar div {
  right: -140px;
  position: relative;
  display: inline-block;
}



.MuiButton-root {
  color: white !important;
}

.MuiMenu-list {
background-color: white !important;
color: white !important;
}

#test-ref {
  color: black !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 20px;
}

.sponsors-banner {
  background-color: rgba(148, 148, 148, 0.486);
  max-height: 50px;
}

.sponsors-banner-msg {
  vertical-align: middle;
  text-align: center;
}
