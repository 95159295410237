.private-section-golf-list {
    background-color: #fff;
    border: black solid 1.3px;
    padding: 5px;
    margin-bottom: 5px;
}

.private-section-golf h3 {
    text-transform: uppercase ;
}

.private-button-list button {
    font-size: 15px;;
    padding: 12px;
    margin: 5px;
    margin-bottom: 15px;
}

/* FORM */

.private-error {
    color: red;
}

.private-signin-h1 {
    text-align: center;
    margin: 5px;
}
.private-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.private-button2 {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.sign-in-container {
  padding: 30px;
  max-width: 75%;
  border: black solid 2px;
  border-radius: 20px;
  margin: auto;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}